import React, { useContext } from 'react';
import {
  Candidate,
  Experience,
  ExperienceEditSchema,
  Taxonomy,
} from '@axiom/validation';
import {
  Banner,
  Button,
  Checkbox,
  CondensedLarge,
  Flashy,
  FluidButtonLayout,
  Form,
  FormGroup,
  FormLabel,
  Gutter,
  Layout,
  TaxonomyUtil,
} from '@axiom/ui';

import { ExperienceApi } from '../../api/experience-api';
import { CandidateExperienceApi } from '../../api/candidate-experience-api';
import { CandidateProfileIssuesApi } from '../../api/candidate-profile-issues-api';

import {
  ExperienceEditSectionKeys,
  ExperienceEditUtil,
} from './experience-edit-util';
import { ExperienceEditContext } from './ExperienceEditContext';
import { ExperienceEditSection } from './ExperienceEditSection';

const { SKILLS } = ExperienceEditSectionKeys;

export const SkillsFormSchema = ExperienceEditSchema.pick({
  generalSkills: true,
});

export type ExperienceEditFormSkillsProps = {
  candidateId: Candidate['id'];
  expandable?: boolean;
  experience: Experience;
  taxonomy: Taxonomy;
};

export const ExperienceEditFormSkills = ({
  candidateId,
  expandable,
  experience,
  taxonomy,
}: ExperienceEditFormSkillsProps) => {
  const {
    dirtyFormState,
    setDirtyFormState,
    accordionState,
    setAccordionState,
  } = useContext(ExperienceEditContext);
  const { generalSkills } = taxonomy;
  const generalSkillOptions =
    TaxonomyUtil.getGeneralSkillOptions(generalSkills);

  return (
    <Form
      name="EXPERIENCE_EDIT_FORM_SKILLS"
      schema={SkillsFormSchema.partial()}
      initialValues={{
        generalSkills: experience.generalSkills ?? [],
      }}
      onSubmit={async formData => {
        const submitData = { ...formData };
        // handle user saving without a selection
        if (!formData.generalSkills) {
          submitData.generalSkills = experience.generalSkills ?? [];
        }
        await ExperienceApi.updateExperience(
          experience.id,
          SkillsFormSchema.parse(submitData)
        );
        await Promise.all([
          CandidateExperienceApi.refreshExperiences(candidateId),
          CandidateProfileIssuesApi.refreshCandidateProfileIssues(candidateId),
        ]);
        setAccordionState({
          ...ExperienceEditUtil.setAllToFalse(accordionState),
          [SKILLS]: true,
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }}
    >
      {({ dirty, fireSubmit }) => {
        // https://stackoverflow.com/questions/61031464/setstate-called-in-render-prop-is-causing-a-react-warning
        setTimeout(() => {
          if (dirtyFormState[SKILLS] !== dirty) {
            setDirtyFormState((prevState: Record<string, boolean>) => ({
              ...prevState,
              [SKILLS]: dirty,
            }));
          }
        }, 0);

        return (
          <ExperienceEditSection
            expandable={expandable}
            expanded={accordionState[SKILLS]}
            isComplete={ExperienceEditUtil.isDataValid(
              SkillsFormSchema,
              experience
            )}
            title="Skills"
            name={SKILLS}
          >
            <div data-test="EXPERIENCE_EDIT_FORM_SKILLS_BODY">
              <FormLabel>Associated skills</FormLabel>
              <Gutter bottom="8px" />
              <Banner>
                <Flashy bold>Coming soon - new skills!</Flashy> We are
                developing a new skills system that is more accurate and will
                help clients to better understand your strengths and abilities.
              </Banner>
              <Gutter bottom="16px" />
              <FormGroup name="generalSkills" label="General Skills (optional)">
                <Layout horizontalGutter="8px" verticalGutter="8px" wrap>
                  {generalSkillOptions.map(({ label, value }) => (
                    <Checkbox
                      key={value}
                      name="generalSkills"
                      displayValue={label}
                      option={value}
                      mode="pill"
                    />
                  ))}
                </Layout>
              </FormGroup>
              <Gutter bottom="16px" />
              <CondensedLarge>
                Do you have suggestions for additional skills to add? Send
                feedback to{' '}
                <a href="mailto:skills-feedback@axiomlaw.com?subject=Skills suggestions&body=Dear Axiom Product Team,%0D%0A%0D%0AI'd like to recommend that Axiom add the following skills:">
                  skills-feedback@axiomlaw.com
                </a>
              </CondensedLarge>
              <Gutter bottom="16px" />
              <FluidButtonLayout>
                <Button
                  pattern="secondary"
                  onClick={fireSubmit}
                  name="EXPERIENCE_EDIT_FORM_SKILLS_SAVE"
                >
                  Save
                </Button>
              </FluidButtonLayout>
            </div>
          </ExperienceEditSection>
        );
      }}
    </Form>
  );
};
