import React from 'react';

export type ProgressGraduationsProps = {
  graduationsCount: number;
};

export const ProgressGraduations = ({
  graduationsCount,
}: ProgressGraduationsProps) => {
  if (!graduationsCount) return null;

  const data = [];
  for (let i = 1; i <= graduationsCount; i++) {
    data.push(
      <div className="graduation" data-test="GRADUATION" key={`G_${i}`} />
    );
  }

  return (
    <div data-test="GRADUATION_CONTAINER" className="progress-graduations">
      {data}
    </div>
  );
};
